import Vue from "vue";
import VueRouter, { RouteConfig, NavigationGuard, Route, RawLocation } from "vue-router";
import store from "@/store/store";
import dirtyness from "@/utilities/Dirtyness";

Vue.use(VueRouter as any); // eslint-disable-line @typescript-eslint/no-explicit-any

let intendedUrl: string | null = null;

// call this in the *component* code (not here in the router setup)
export function beforeRouteLeave(to: Route, from: Route,  next: (to?: string | void | false) => void) {
    if(dirtyness.isDirty && !confirm("Changes may not be saved.  Click cancel to stay on this page.")) {
        return next(false);
    }
    dirtyness.reset();
    return next();
}

const beforeEnterGuard: NavigationGuard = async (to: Route, from: Route, next: (to?: string | void) => void) => {

    //console.log(`### nav guard... signed in=${store.getters.isSignedIn} intendedUrl=${intendedUrl}`);

    if (store.getters.isSignedIn) {
        if (intendedUrl) {
            const url = intendedUrl;
            intendedUrl = null;
            console.log("### nav guard - now logged in - redirect");
            return next(url); // goto stored url
        } else {
            console.log("### nav guard - carry on...");
            return next(); // all is fine
        }
    }

    if (store.getters.isSignedIn) {
        console.log("### nav guard - got login ok");
        next();
    } else {
        intendedUrl = to.path; // store entry url before redirect
        console.log("### nav guard - redirect to signin");
        next("/signIn");
    }
};

//
// -- set up the routes
//

const routes: Array<RouteConfig> = [];

routes.push({ path: "/landingPage/:retailer/:guid", name: "LandingPage", meta: { layout: "blank" }, component: () => import("@/vue/views/LandingPage.vue") });
routes.push({ path: "/", name: "Translations", meta: { layout: "blank" }, component: () => import("@/vue/views/Translations.vue") });

//
// -- create the router
//

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    linkExactActiveClass: "active",
    base: process.env.BASE_URL,
    routes
});

export function goToIntendedUrl() {
    if (!intendedUrl) router.replace("/"); // default in case not defined (?)
    router.replace(intendedUrl as RawLocation);
}

export default router;
