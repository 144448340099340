import Vue from "vue";
import Vuetify, { UserVuetifyPreset } from "vuetify";
import InfiniteLoading from "vue-infinite-loading";

import App from "@/vue/App.vue";
import router from "@/router/router";
import store from "@/store/store";

import "@/utilities/CustomFilters";

// CSS

import "@fontsource/roboto";
import "@mdi/font/css/materialdesignicons.css"
import "toastr/build/toastr.css"; 
import "vuetify/dist/vuetify.css";
import "@/css/site.scss";

// https://peachscript.github.io/vue-infinite-loading/guide/configure-plugin-opts.html#props-settings
Vue.use(InfiniteLoading, {
    props: { spinner: "spiral" },
    slots: { noMore: "", noResults: ""  }
});

import Blank from "@/vue/layouts/Blank.vue";
import SignedOut from "@/vue/layouts/Visitor.vue";
import dirtyness from "@/utilities/Dirtyness";
import ErrorReporter, { IErrorReport } from "./utilities/ErrorReporter";

Vue.component("blank-layout", Blank);
Vue.component("visitor-layout", SignedOut);

Vue.config.productionTip = false;

//
// -- Global error handling and reporting
//

Vue.config.errorHandler = ErrorReporter.vueErrorHandler;
// Vue.config.warnHandler = (message: string, vm: Vue, trace: string) => { blee... };
window.onerror = ErrorReporter.windowErrorHandler;

//
// -- dirty-check - leaving page
//

window.addEventListener("beforeunload", (event?: Event) => {
    if(!event) {
        event = window.event;
    }
    if (dirtyness.isDirty) {
        if(event) {
            event.preventDefault();
            event.returnValue = true;
        }
        // I don't think messages like this are supported in modern browsers but...
        return "It looks like you have not saved your changes."; 
    }
    else {
        if(event){
            // From MDN docs: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete (event as any)["returnValue"];
        }
        // no real point in calling reset() since we're leaving the site but might as well...
        dirtyness.reset(); 
        return undefined;
    }
});

//
// -- vuetify
//

Vue.use(Vuetify);
const opts: Partial<UserVuetifyPreset> = {};
const vuetify: Vuetify = new Vuetify(opts);

//
// -- Mount the application!
//

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount("#app");