/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from "vue";
import Vuex, { StoreOptions, MutationTree, ActionTree, ActionContext } from "vuex";
import { ILanguage } from "@/model/Language";

Vue.use(Vuex);

//
// -- This allows us to keep globally available data
//    that we can bind to in components...
//

// Define what we're storing
export interface IStoreState {
    pendingApiCall: Promise<any> | null;
    languages: Array<ILanguage> | null;
    retailer: number | null;
}

//
// -- build an options object
//

const options: StoreOptions<IStoreState> = { 
    state: () => ({
        // shared state
        pendingApiCall: null,
        languages: null as Array<ILanguage>|null,
        retailer: null
    }),
    getters: {
        languages: (state: IStoreState) => (state.languages !== null),
        retailer: (state: IStoreState) => (state.retailer !== null)
    },
    mutations: {},  // added below
    actions: {},    // added below
    modules: {}     // guess we don't need this...
};

// couple of shortcuts (and tell subsequent typescript they're *not* undefined)
const mutations = options.mutations as MutationTree<IStoreState>;
const actions = options.actions as ActionTree<IStoreState, IStoreState>;

// used during signing back in
mutations.setPendingApiCall = (state: IStoreState, pendingApiCall: Promise<any>) => {
    state.pendingApiCall = pendingApiCall;
};

actions.setPendingApiCall = (context: ActionContext<IStoreState, IStoreState>, pendingApiCall: Promise<any>) => {
    //console.log("STORE - action - setPendingApiCall");
    context.commit("setPendingApiCall", pendingApiCall);
};

mutations.setLanguages = (state: IStoreState, languages: Array<ILanguage>) => {
    state.languages = languages;
};

actions.setLanguages = (context: ActionContext<IStoreState, IStoreState>, languages: Array<ILanguage>) => {
    //console.log("STORE - action - setLanguages");
    context.commit("setLanguages", languages);
};

mutations.setRetailer = (state: IStoreState, retailer: number) => {
    state.retailer = retailer;
};

actions.setRetailer = (context: ActionContext<IStoreState, IStoreState>, retailer: number) => {
    //console.log("STORE - action - setRetailer");
    context.commit("setRetailer", retailer);
};

//
// -- the main deal - export the Store
//

export default new Vuex.Store<IStoreState>(options);
