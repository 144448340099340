
    import Vue from "vue";
    import Component from "vue-class-component";
    import eventBus from "@/utilities/EventBus";
    import apiClient, { ISendRequestParameters } from "@/utilities/ApiClient";
    import * as toastr from "toastr";

    const defaultLayout = "visitor";

    @Component({
        components: {  }
    })
    export default class App extends Vue {

        private pendingApiParameters: ISendRequestParameters | null = null;

        mounted() {

            // Prevent any XSS shenanigans by default
            toastr.options.escapeHtml = true;

            eventBus.$on("http-401", (parameters: ISendRequestParameters) => {
                this.pendingApiParameters = parameters;
                console.log("...App component - setting promise and showing login dialogue");
            });
        }

        onAuthenticated() {
            console.log("...App component - onAuthenticated");
            if (this.pendingApiParameters == null) {
                console.log("...App component - onAuthenticated - pendingApiParameters is **NULL**!");
                // what should we do if we ever get here?!!
                return;
            }            
            console.log("...App component - resend last API request");
            apiClient.sendRequest(this.pendingApiParameters);
            this.pendingApiParameters = null;
        }

        // computed property used to select layout - see also main.ts
        get layout() {
            if (!this.$route.meta?.layout) return null;
            return (this.$route.meta?.layout || defaultLayout) + "-layout";
        }
    }
